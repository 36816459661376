import classNames from 'classnames';
import imagesloaded from 'imagesloaded';
import _ from 'lodash';
import React from 'react';
import { createPortal } from 'react-dom';
import { FlickityOptions } from '../../flickity';
import * as styles from './flickity_component.module.less';

interface Props {
  className: string;
  disableImagesLoaded: boolean;
  elementType: string;
  flickityRef: (el: any) => void;
  options: FlickityOptions;
  reloadOnUpdate: boolean;
  static: boolean;
  onStaticClick: (
    event: PointerEvent,
    pointer: PointerEvent,
    cellElement: any,
    cellIndex: number
  ) => void;
}

interface State {
  flickityReady: boolean;
}

const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

const FLICKITY_STATIC_CLICK = 'staticClick';

export default class FlickityComponent extends React.Component<Props, State> {
  flkty: any = null;
  carousel: any = null;
  state = {
    flickityReady: false,
  };

  componentDidUpdate(prevProps: Props & { children: any[] }, prevState: State) {
    const {
      children,
      options: { draggable, initialIndex },
      reloadOnUpdate,
      disableImagesLoaded,
    } = this.props;
    const { flickityReady } = this.state;

    if (reloadOnUpdate || (!prevState.flickityReady && flickityReady)) {
      this.flkty.deactivate();

      // reset selected index if children have changed
      const prevChildKeys = prevProps.children.map((c) => c.key);
      const childKeys = (children as any[]).map((c) => c.key);
      if (!_.isEqual(prevChildKeys, childKeys)) {
        this.flkty.selectedIndex = initialIndex || 0;
      }

      this.flkty.options.draggable =
        draggable === undefined ? (children ? (children as []).length > 1 : false) : draggable;
      this.flkty.activate();
      if (!disableImagesLoaded && this.carousel) {
        imagesloaded(this.carousel, () => {
          this.flkty.reloadCells();
        });
      }
    } else {
      this.flkty.reloadCells();
    }
  }

  componentDidMount(): void {
    if (!canUseDOM) {
      return null;
    }
    const { disableImagesLoaded, flickityRef, options, onStaticClick } = this.props;
    const carousel = this.carousel;
    const Flickity = require('flickity');
    this.flkty = new Flickity(carousel, options);

    if (onStaticClick != null) {
      this.flkty.on(FLICKITY_STATIC_CLICK, onStaticClick);
    }

    if (disableImagesLoaded) {
      this.setFlickityToReady();
    } else {
      imagesloaded(carousel, this.setFlickityToReady);
    }
    if (flickityRef) {
      flickityRef(this.flkty);
    }
  }

  setFlickityToReady = () => {
    this.setState({ flickityReady: true });
  };

  renderPortal() {
    if (!this.carousel) {
      return null;
    }
    const mountNode = this.carousel.querySelector('.flickity-slider');
    if (mountNode) {
      return createPortal(this.props.children, mountNode);
    }
  }

  render() {
    const { elementType, className, children } = this.props;

    return React.createElement(
      elementType,
      {
        className: classNames(styles.useless, className),
        ref: (c) => {
          this.carousel = c;
        },
      },
      this.props.static ? children : this.renderPortal()
    );
  }
}
